import Image from 'next/image';
import noimage from 'img/noimage.jpeg';

export default function SiteImage(props) {
  const { src, alt = '' } = props;
  return src ? (
    <Image {...props} alt={alt} />
  ) : (
    <Image {...props} src={noimage} alt={alt} />
  );
}
