import { LOCAL_KEYS, PRODUCT_TYPE } from 'Lib/constants/page';

import { v4 } from 'uuid';

/**
 * Extracts and returns float value from a string.
 *
 * @param {string} string String
 * @return {any}
 */
export const getFloatVal = (string) => {
  let floatValue = string.match(/[+-]?\d+(\.\d+)?/g)[0];
  return null !== floatValue
    ? parseFloat(parseFloat(floatValue).toFixed(2))
    : '';
};

/**
 * Add first product.
 *
 * @param {Object} product Product
 * @return {{totalProductsCount: number, totalProductsPrice: any, products: Array}}
 */
export const addFirstProduct = (product) => {
  let productPrice = getFloatVal(product.price);

  let newCart = {
    products: [],
    totalProductsCount: 1,
    totalProductsPrice: productPrice
  };

  const newProduct = createNewProduct(product, productPrice, 1);
  newCart.products.push(newProduct);

  localStorage.setItem('woo-next-cart', JSON.stringify(newCart));

  return newCart;
};

/**
 * Create a new product object.
 *
 * @param {Object} product Product
 * @param {Integer} productPrice Product Price
 * @param {Integer} qty Quantity
 * @return {{image: *, productId: *, totalPrice: number, price: *, qty: *, name: *}}
 */
export const createNewProduct = (product, productPrice, qty) => {
  return {
    productId: product.productId,
    image: product.image,
    name: product.name,
    price: productPrice,
    qty,
    totalPrice: parseFloat((productPrice * qty).toFixed(2))
  };
};

/**
 * Updates the existing cart with new item.
 *
 * @param {Object} existingCart Existing Cart.
 * @param {Object} product Product.
 * @param {Integer} qtyToBeAdded Quantity.
 * @param {Integer} newQty New Qty to be updated.
 * @return {{totalProductsCount: *, totalProductsPrice: *, products: *}}
 */
export const updateCart = (
  existingCart,
  product,
  qtyToBeAdded,
  newQty = false
) => {
  const updatedProducts = getUpdatedProducts(
    existingCart.products,
    product,
    qtyToBeAdded,
    newQty
  );

  const addPrice = (total, item) => {
    total.totalPrice += item.totalPrice;
    total.qty += item.qty;

    return total;
  };

  // Loop through the updated product array and add the totalPrice of each item to get the totalPrice
  let total = updatedProducts.reduce(addPrice, { totalPrice: 0, qty: 0 });

  const updatedCart = {
    products: updatedProducts,
    totalProductsCount: parseInt(total.qty),
    // subtotalProductPrice: parseFloat(total.totalPrice)
    totalProductsPrice: parseFloat(total.totalPrice)
  };

  localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));

  return updatedCart;
};

/**
 * Get updated products array
 * Update the product if it exists else,
 * add the new product to existing cart,
 *
 * @param {Object} existingProductsInCart Existing product in cart
 * @param {Object} product Product
 * @param {Integer} qtyToBeAdded Quantity
 * @param {Integer} newQty New qty of the product (optional)
 * @return {*[]}
 */
export const getUpdatedProducts = (
  existingProductsInCart,
  product,
  qtyToBeAdded,
  newQty = false
) => {
  // Check if the product already exits in the cart.
  const productExitsIndex = isProductInCart(
    existingProductsInCart,
    product.productId
  );

  // If product exits ( index of that product found in the array ), update the product quantity and totalPrice
  if (-1 < productExitsIndex) {
    let updatedProducts = existingProductsInCart;
    let updatedProduct = updatedProducts[productExitsIndex];

    // If have new qty of the product available, set that else add the qtyToBeAdded
    updatedProduct.qty = newQty
      ? parseInt(newQty)
      : parseInt(updatedProduct.qty + qtyToBeAdded);
    updatedProduct.totalPrice = parseFloat(
      (updatedProduct.price * updatedProduct.qty).toFixed(2)
    );

    return updatedProducts;
  } else {
    // If product not found push the new product to the existing product array.
    let productPrice = getFloatVal(product.price);
    const newProduct = createNewProduct(product, productPrice, qtyToBeAdded);
    existingProductsInCart.push(newProduct);

    return existingProductsInCart;
  }
};

/**
 * Returns index of the product if it exists.
 *
 * @param {Object} existingProductsInCart Existing Products.
 * @param {Integer} productId Product id.
 * @return {number | *} Index Returns -1 if product does not exist in the array, index number otherwise
 */
const isProductInCart = (existingProductsInCart, productId) => {
  const returnItemThatExits = (item, index) => {
    if (productId === item.productId) {
      return item;
    }
  };

  // This new array will only contain the product which is matched.
  const newArray = existingProductsInCart.filter(returnItemThatExits);

  return existingProductsInCart.indexOf(newArray[0]);
};

/**
 * Remove Item from the cart.
 *
 * @param {Integer} productId Product Id.
 * @return {any | string} Updated cart
 */
export const removeItemFromCart = (productId) => {
  let existingCart = localStorage.getItem('woo-next-cart');
  existingCart = JSON.parse(existingCart);

  // If there is only one item in the cart, delete the cart.
  if (1 === existingCart.products.length) {
    localStorage.removeItem('woo-next-cart');
    return null;
  }

  // Check if the product already exits in the cart.
  const productExitsIndex = isProductInCart(existingCart.products, productId);

  // If product to be removed exits
  if (-1 < productExitsIndex) {
    const productTobeRemoved = existingCart.products[productExitsIndex];
    const qtyToBeRemovedFromTotal = productTobeRemoved.qty;
    const priceToBeDeductedFromTotal = productTobeRemoved.totalPrice;

    // Remove that product from the array and update the total price and total quantity of the cart
    let updatedCart = existingCart;
    updatedCart.products.splice(productExitsIndex, 1);
    updatedCart.totalProductsCount =
      updatedCart.totalProductsCount - qtyToBeRemovedFromTotal;
    updatedCart.totalProductsPrice =
      updatedCart.totalProductsPrice - priceToBeDeductedFromTotal;

    localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));
    return updatedCart;
  } else {
    return existingCart;
  }
};

/**
 * Returns cart data in the required format.
 * @param {String} data Cart data
 */
export const getFormattedCart = (data) => {
  let formattedCart = null;

  if (undefined === data || !data.cart.contents.nodes.length) {
    return formattedCart;
  }

  const givenProducts = data.cart.contents.nodes;

  // Create an empty object.
  formattedCart = {};
  formattedCart.products = [];
  let totalProductsCount = 0;

  for (let i = 0; i < givenProducts.length; i++) {
    const givenProduct =
      givenProducts?.[i]?.product.node.type === PRODUCT_TYPE.VARIABLE
        ? givenProducts?.[i]?.variation?.node
        : givenProducts?.[i]?.product?.node;
    const product = {};
    const total = getFloatVal(givenProducts[i].total);
    currencyToNumber(givenProduct?.priceFormated);
    product.productId = givenProduct?.productId ?? '';
    product.cartKey = givenProducts?.[i]?.key ?? '';
    product.name = givenProduct?.name ?? '';
    product.price = givenProduct?.price;
    product.regularPrice = givenProduct?.regularPrice;
    product.image = givenProduct?.image ?? '';
    product.onSale = givenProduct?.onSale ?? false;
    product.qty = givenProducts?.[i]?.quantity;
    product.totalPrice = givenProducts?.[i]?.total ?? '';
    totalProductsCount += givenProducts?.[i]?.quantity;

    // Push each item into the products array.
    formattedCart.products.push(product);
  }

  formattedCart.totalProductsCount = totalProductsCount;
  formattedCart.subtotal = data?.cart?.subtotal ?? '';
  formattedCart.shippingTotal = data?.cart?.shippingTotal ?? '';
  formattedCart.totalProductsPrice = data?.cart?.total ?? '';

  return formattedCart;
};

export const createCheckoutData = (order) => {
  const billingData = order.billing;
  const checkoutData = {
    clientMutationId: v4(),
    shipping: {
      firstName: order?.shipping?.firstName,
      lastName: order?.shipping?.lastName,
      address1: order?.shipping?.address1,
      address2: order?.shipping?.address2,
      city: order?.shipping?.city,
      country: order?.shipping?.country,
      state: order?.shipping?.state,
      postcode: order?.shipping?.postcode,
      email: order?.shipping?.email,
      phone: order?.shipping?.phone,
      company: order?.shipping?.company,
      ward: order?.shipping?.ward
    },
    billing: {
      firstName: billingData?.firstName,
      lastName: billingData?.lastName ?? '',
      address1: billingData?.address1 ?? '',
      address2: billingData?.address2 ?? '',
      city: billingData?.city,
      country: billingData?.country,
      state: billingData?.state,
      postcode: billingData?.postcode,
      email: billingData?.email,
      phone: billingData?.phone,
      company: billingData?.company,
      ward: billingData?.ward
    },
    shipToDifferentAddress: order.billingDifferentThanShipping,
    paymentMethod: order.paymentMethod,
    shippingMethod: order.shippingMethod,
    isPaid: false
  };

  if (order.createAccount) {
    checkoutData.account = {
      username: order.username,
      password: order.password
    };
  }
  return checkoutData;
};

/**
 * Get the updated items in the below format required for mutation input.
 *
 * [
 * { "key": "33e75ff09dd601bbe6dd51039152189", "quantity": 1 },
 * { "key": "02e74f10e0327ad868d38f2b4fdd6f0", "quantity": 1 },
 * ]
 *
 * Creates an array in above format with the newQty (updated Qty ).
 *
 */
export const getUpdatedItems = (products, newQty, cartKey) => {
  // Create an empty array.
  const updatedItems = [];

  // Loop through the product array.
  products.map((cartItem) => {
    // If you find the cart key of the product user is trying to update, push the key and new qty.
    if (cartItem.cartKey === cartKey) {
      updatedItems.push({
        key: cartItem.cartKey,
        quantity: parseInt(newQty)
      });

      // Otherwise just push the existing qty without updating.
    } else {
      updatedItems.push({
        key: cartItem.cartKey,
        quantity: cartItem.qty
      });
    }
  });

  // Return the updatedItems array with new Qtys.
  return updatedItems;
};

/**
 * Shorten inputted string (usually product description) to a maximum of length
 * @param {String} string The string that we input
 * @param {Integer} length The length that we want to shorten the text to
 */
export const trimmedStringToLength = (string, length) => {
  if (string.length > length) {
    const subStr = string.substring(0, length);
    return `${subStr}...`;
  }
  return string;
};

/**
 *
 * @param {String} src the image src string
 * @param {Integer} width the image with
 * @param {Integer} the quality of image
 */
export function cloudinaryLoader({ src, width, quality }) {
  const params = [
    'f_auto',
    'c_limit',
    'w_' + width,
    'q_' + (quality || 'auto')
  ];
  const paramsString = params.join(',') + '/';
  return `https://res.cloudinary.com/${process.env.CLOUDINARY_ID}/image/upload/${paramsString}${src}`;
}

export const currencyToNumber = (currency) =>
  Number(currency?.replace(/[^0-9.-]+/g, ''));

export const getShippingAddress = () => {
  if (process.browser) {
    let address = getAddressList();
    if (address && address.length > 0) {
      let result = address.find((elem) => elem.isDefault);
      return result;
    }
    return null;
  }

  return null;
};

export const getAddressList = () => {
  if (process.browser) {
    let address = localStorage.getItem(LOCAL_KEYS.SHIPPING);
    if (address) {
      let result = JSON.parse(address);
      return result;
    }
    return null;
  }
  return null;
};

export const setDeliveryAddress = (position) => {
  let address = getAddressList();
  if (address && address.length > 0) {
    address.forEach((elem, index) => (elem.isDefault = index == position));
    localStorage.setItem(LOCAL_KEYS.SHIPPING, JSON.stringify(address));
  }

  return address;
};

export const getBanner = (banners) => {
  let result = {
    mobile: [],
    desktop: []
  };
  banners.forEach((item) => {
    result[item.node.bannerMeta.showIn].unshift(bannerPopulate(item));
  });

  return result;
};

const bannerPopulate = (item) => {
  const { sourceUrl, altText, cloudinaryResource } =
    item.node.featuredImage.node;
  const { bannerMeta } = item.node;

  return {
    sourceUrl,
    altText,
    cloudinaryResource,
    link: bannerMeta.bannerLink,
    showIn: bannerMeta.showIn
  };
};

export const stripHtmlTags = (originalString) => originalString.replace(/(<([^>]+)>)/gi, "");

export const encodeHTML = function (str) {
  return str.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
};

export const getLowestProductPrice = (price) => {
  if(price != null && price.indexOf('-') > 0) {
    return price.substring(0, price.indexOf('-') - 1)
  }

  return price;
}