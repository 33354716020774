import {
  close_button,
  inner,
  item,
  nav_active,
  navbar_toggle,
  overlay,
  sidenav_wrapper
} from 'Styles/modules/m-nav.module.scss';
import { useContext, useState } from 'react';

import Link from 'next/link';
import { MenuContext } from 'Context/MenuContext';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useRouter } from 'next/router';

export default function MobileNav({ enabled = false, className }) {
  const router = useRouter();
  const [enableSideNav, setEnableSideNav] = useState(enabled);
  const { sideMenuItems } = useContext(MenuContext);
  const navActive = enableSideNav ? nav_active : '';

  const handleClick = (e) => {
    const { href } = e.target;
    e.preventDefault();
    setEnableSideNav(false);
    router.push(href, href);
  };

  const handleClose = () => {
    setEnableSideNav(false);
  };

  return (
    <>
      <div
        className={classNames(navbar_toggle, className)}
        onClick={() => setEnableSideNav(!enableSideNav)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        className={classNames(overlay, navActive)}
        onClick={() => setEnableSideNav(false)}
      />
      <aside
        className={classNames(sidenav_wrapper, navActive)}
        id="sidenavWrapper"
      >
        <span className={close_button} onClick={handleClose}>
          <XIcon className="h-6 w-6" />
        </span>
        <div className={inner}>
          <ul>
            {sideMenuItems &&
              sideMenuItems.map((elem) =>
                <li key={elem.id} className={item}>
                  <Link href={elem.path}>
                    <a onClick={handleClick}>{elem.name}</a>
                  </Link>
                </li>
              )}
          </ul>
        </div>
      </aside>
    </>
  );
}
