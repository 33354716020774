export const PRODUCT_TYPE = {
  SIMPLE: 'SIMPLE',
  VARIABLE: 'VARIABLE'
};

export const LOCAL_KEYS = {
  SHIPPING: 'shipping-address',
  CART: 'woo-next-cart',
  CART_SESSION: 'woo-session'
};

export const COLLECTION_TERMS = {
  BEST_SELLER: 'best-seller',
  NEW_ARRIVALS: 'new-arrivals',
  WOMAN_PERFUME: 'nuoc-hoa-cho-nu',
  MAN_PERFUME: 'nuoc-hoa-cho-nam',
  GIFT_SET: 'bo-qua-tang-nuoc-hoa',
  UNISEX_PERFUME: 'nuoc-hoa-unisex',
  HOT_SALE: 'hot-sale'
};

export const PRODUCT_LIST_TYPE = {
  ProductCategory: 'category',
  ProductCollection: 'collectionIn'
};

export const PRODUCT_LIST_LIMIT = 20;
export const PRODUCT_MORE_LIMIT = 10;

export const ALLOWED_SORT_KEY = ['DATE', 'MODIFIED', 'SLUG', 'PRICE'];
export const ALLOWED_ORDER = ['ASC', 'DESC'];
export const ALLOWED_FILTER_KEY = ['TAG', 'ONSALE'];
